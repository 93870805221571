import {Cookies} from 'quasar';

export function EXPORT_APPLICATIONS({state}) {

  const accessToken = Cookies.get('accessToken');

  const url = process.env.API_BASE_URL
    + '/events/exportApplications/'
    + state.item.id
    + '?access_token='
    + accessToken;

  window.open(url, '_blank');
}
